import * as React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const UeberMich = (props) => {
  const data = props.data.allUebermichYaml.edges;
  return (
    <>
      <Layout
        title="Über Mich"
        image={data[0].node.img.childImageSharp.gatsbyImageData}
      >
        <main class="container">
          <h1>Wir...</h1>
          <section className="row">
            <div className="col-md-12">
              <p>
                ...das bin ich, Margit Westermann und meine Golden Retriever
                Mädels. Wir sind vor einigen einigen Jahren nach Malsch bei
                Karlsruhe zogen. In dieser schönen ländlichen Umgebung lässt es
                sich prima mit Hund leben und Arbeiten. Wald, Wiesen, Felder und
                Seen laden zu langen Spaziergängen und zum Baden ein - also
                alles was ein Retrieverherz höher schlagen lässt, findet sich
                hier.
              </p>
              <p>
                Seit fast 20 Jahren züchte ich nun diese wundervolle Hunderasse.
                Dies habe ich nun zum Anlass genommen, die alte HP zu
                überarbeiten. Was da an Emotionen drinn steckt, kann man kaum
                erfassen. Ich habe es trotzdem versucht, Ihnen einen Einblick zu
                vermitteln, wie mich dieses Hobby gepackt hat - und zu meiner
                Leindenschaft wurde.
              </p>
            </div>
            <div className="col-lg-6">
              <GatsbyImage
                className="shadow"
                image={data[1].node.img.childImageSharp.gatsbyImageData}
                alt="Ich 1"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6">
              <p>
                Meine Mädchen wohnen selbstverständlich alle im Haus. Sie haben
                höchstens 2 oder 3 Würfe in ihrem Leben, manche hatten auch
                keinen Wurf und sind trotzdem geblieben. Denn in erster LInie
                sind es meine Mädchen und wenn ich mich einmal für solch ein
                zauberhaftes Wesen entschieden haben, dann ein Hundeleben lang.
                So bleiben auch meine Mädchen, die aus der Zucht sind, bei mir.
              </p>
              <p>
                Eine eigene Zuchtlinie aufzubauen ist etwas wunderbares.
                Manchmal geht es nicht und Töchter sterben vor ihren Müttern,
                oder die Zuchtlinie läuft aus, weil die Hündin nicht mehr
                tragend wurde. Das alles gehört zum Leben eines Züchters.
              </p>
              <p>
                Ich habe das große Glück, dass die nächste Generation an den
                Start geht. Meine sanfte Anjuly. Sie ist eine Tochter von Kiss
                me Quick f. Golden Eyes of Munroe. Leider konnte meine geliebte
                Phoebe ihre Ur-Enkelin nicht mehr kennen lernen - sie starb kurz
                zuvor. Anjuly´s Oma Rosalie begleitete hier ihre ersten Schritte
                und zeigt ihr hier - wie alles so geht.
              </p>
            </div>
            <div className="col-md-12">
              <p>
                Ich bin mit Hunden aufgewachsen, mein Vater war Ausbilder für
                den Schutzhundedienst und züchtete selbst Schäferhunde. Die
                Wochenenden verbrachte ich auf dem Hundeplatz, unter der Woche
                versorgte ich die Welpen. Ich lernte viel über Erziehung und das
                Verhalten der Hunde und bin sehr froh, dass sich in den letzten
                Jahren doch einiges Neues getan hat.
              </p>
              <p>
                Vor 30 Jahren habe ich im Urlaub einen Golden-Retriever gesehen
                und mich sofort in diese Hunderasse verliebt. Der liebevolle
                Ausdruck und das Temperament dieser Hunde, ihr “ will to please
                “ und ihre Sanftheit hatten es mir angetan und ich wollte
                unbedingt- wenn das Umfeld und die Zeit es zulässt - einen
                solchen Hund.
              </p>
              <p>
                So kam ich zu unserer ersten Golden- Hündin Molly. Und aus
                Interesse wurde Passion. Hundebücher lesen, Seminare und
                Ausstellungen besuchen, neue Verhaltens- und Erziehungsregeln
                wurden mein Steckenpferd und nach weiteren Jahren hatte ich dann
                endlich Zeit und den genügenden Platz um nach langem Suchen eine
                zweite Hündin in unsere Familie aufzunehmen und mit der Zucht zu
                beginnen.
              </p>
            </div>
            <div className="col-lg-6">
              <p>
                Leider war es meiner geliebten Cathy-Lynn nicht vergönnt, lange
                zu leben. Sie verstarb - wenige Monate nach ihren ersten Wurf -
                am 28.12.2004 um 19:40 Uhr zu hause in meinen Armen. Ich
                vermisse sie sehr.
              </p>
              <p>
                Mir liegt sehr viel daran, die Welpen optimal aufzuziehen. Die
                Wurfkiste steht im Wohnzimmer. Hier werden sie in meine Hände
                geboren. Ich schlafe 4 Wochen vor der Wurfkiste- da beginnt
                bereits die Bindung zum Menschen. Sie werden täglich gewogen,
                beschmust und geherzt. Altersentsprechend gewöhne ich sie an
                Geräusche, verschiedene Bodenbeläge, Staubsauger und
                Geschirrspüler, das Hantieren in der Küche, der TV. Ab der 4.
                Woche dürfen die Welpeneltern zu Besuch kommen. So lernen die
                Kleinen auch verschiedene Menschen kennen. Ich konditioniere die
                Kleinen bereits auf Pfeife - das Kommando für " hier " ist dann
                bereits bei Abgabe bekannt.
              </p>
            </div>
            <div className="col-lg-6">
              <GatsbyImage
                className="shadow"
                float-right
                image={data[2].node.img.childImageSharp.gatsbyImageData}
                alt="Ich 1"
                loading="lazy"
              />
            </div>
            <div className="col-md-12">
              <p>
                Nach der 4. Woche darf das kleine Volk dann in den Garten. Erde
                graben, Würmer fressen, spielen, toben und sich mal richtig den
                Wind um die Nase wehen lassen, dass steht dann auf dem Programm.
                Tunnel, Flatterband, Rappeldosen, verschiedene Untergründe,
                Höhlen und bewegliche Objekte - alles dürfen die Kleinen kennen
                lernen.
              </p>
              <p>
                Mit 8 Wochen gehen die Kleinen dann in ihre neuen Familien.Dann
                sind sie bereits 4 x entwurmt, vorgeimpft, gechippt und bereit,
                die Welt zu entdecken. Dabei ist immer ein kleiner " Rucksack "
                mit den Unterlagen von Mutter u. Vater, Futterplan, Terminliste,
                Ratschläge für die ersten Tage zuhause und vieles mehr.
              </p>
              <p>
                Ich wünsche mir einen engen Kontakt mit meinen Welpeneltern und
                freue mich immer über eine Rückmeldung. Meinen Welpeneltern
                stehe ich mit Rat und Tat ein ganzes Hundeleben lang zur Seite.
                Wir treffen uns immer wieder zu Welpentreffen, Dummykursen,
                Trimmkursen, Ringtraining oder einfach so. Eine Tasse Kaffee
                steht immer bereit.
              </p>
            </div>
          </section>
          {/* <p>
            Ihre Kinder haben sich zu prächtigen Golden entwickelt, die ihren
            Familien viel Freude bringen. Ihre Tochter Amazing Grace - Paige -
            durfte bei uns bleiben und macht mir viel Freude.Sie hat das
            aufgeweckte und freundliche Wesen ihrer Mutter und ist auch auf
            Ausstellungen sehr erfolgreich. Sie liebt Menschen über alles und
            würde am liebsten alle zu einer Party einladen. Leider wird diese
            wundervolle Hündin wegen einer ED nicht in die Zucht gehen. Ihre
            Passion ist der Hundebesuchsdienst im Altenheim und das Mitaufziehen
            als Nanny. Sie selbst hatte niemals Welpen, vielleicht fühlt sie
            sich deshalb so zu ihnen hin gezogen und hilft bei jedem Wurf
            fabelhaft mit. Eine bessere Nanny gibt es nicht!
          </p>
          <p>
            Im Sommer `05 kam aus dem Hause Sponholz unser Sonnenschein In the
            Mood of Redpine - Phoebé - zu uns. Eine sehr feminine, sanfte und
            leichtführige Hündin. Ein Gegenpool zu unserer temperamentvollen
            Paige.
          </p>
          <p>
            Seit Frühjahr 2006 Jahres darf ich noch die Hündin Wish me Luck of
            Redpine - Luca - zu unserer Familie zählen. Diese sanfte zauberhafte
            cremefarbene Hündin hat uns das Glück wieder ins Haus gebracht. Mit
            ihr hatte ich meinen B- und C- Wurf. Sie ist überall da, wo ich bin
            und selbst bei der Geburt ihrer Welpen wollte sie die ganze Familie
            bei sich haben. Nun feierte sie bereits ihren 13. Geburtstag und
            erfreut sich bester Gesundheit. Ich hoffe, dass sie noch ganz lange
            bei uns bleibt - sie ist so eine geduldige Großmutter.
          </p>
          <p>
            Mein ganz besonderer Dank gilt an dieser Stelle Sylvia und Uwe
            Sponholz - Zwinger “ Redpine “ . Ohne Euch - liebe Sylvia und Uwe -
            währe dass alles nicht möglich gewesen. Wir werden Euch das nie
            vergessen. DANKE !!!!!!
          </p>
          <p>
            Ich hatte das große Glück aus dem B-Wurf eine Hündin behalten zu
            dürfen - Briannah ( Meggie ). Sie hat das gleiche zauberhafte Wesen
            ihrer Mutter und diesen Ausdruck im Gesicht, den ich schon lange
            nicht mehr gesehen habe..... Einige ihrer Kinder sind bereits selbst
            in der Zucht und auf Ausstellungen sehr erfolgreich.
          </p>
          <p>
            Nun ist unsere Meute komplett ( aus dem nächsten Wurf wird kein
            Welpe behalten ....) und die Zukunft liegt bei Phobé und Meggie. -
            So war es zumindest gedacht.
          </p>
          <p>
            Mit Phoebé hatten wir unseren D-Wurf und zum ersten Mal habe ich
            eine Hündin zurückgeholt. Die kleine Fiona ( Daydream ) hatte keinen
            guten Start und auch die nochmalige spätere Vermittlung in eine
            Familie scheiterte nach 3 Tagen!!!! Danach hatte ich erst einmal die
            Nase voll und so wuchs “ unser Baby “ bei uns zu einer wunderschönen
            cremefarbenen Hündin heran. Leider konnte ich sie nicht behalten.
            Inzwischen ist sie in eine liebevolle Familie vermittelt, wo sie
            zusammen mit einer erwachsenen Golden Hündin ihren Platz im Leben
            gefunden hat. Sie wird in einer neuen Familie genau so viel Wärme
            verbreiten, wie sie es hier getan hat.
          </p>
          <p>
            Im Mai 2009 hat nun meine Meggie ihre ersten Welpen bekommen und aus
            diesem Wurf haben wir die kleine Èowyn (Holly) behalten. In ihr
            verbinden sich irische Linien und so ist sie auch etwas ganz
            besonderes. Hier ist der Name Progamm. Halb Kämpferin, halb
            Prinzessin - eine Irin eben. Leider hatte sie als Junghund eine
            schwere Weichteilverletzung am Vorderfuss und wir wussten lange
            nicht, ob sie das Beinchen behalten kann. Aber unsere Kämpferin hat
            sich durchgesetzt und heute ist alles wieder in Ordnung. Ihr
            Gangwerk ist kraftvoll und ausladend mit viel Schub aus der
            Hinterhand. Ihr Lieblingsplatz ist die Ausstellung. Im Ring
            verändert sie sich völlig, sie schwebt geradezu. Leider wird sie
            wegen einer HD nicht in die Zucht gehen, ist jedoch allen Welpen
            eine liebevolle Tante, die ihnen zeigt, wie man einen Garten
            umgeststalten kann......
          </p>
          <p>
            Aus Phoebés H-Wurf ist Rosalie ( Hills Primrose ) bei mir geblieben.
            Sie ist mit ihren 1 1/2 Jahren immer noch der Clown im Rudel. Jeden
            Tag heckt sie was neues aus - liegt vielleicht auch daran, dass ich
            sie ein bischen verwöhnt habe:-)
          </p>
          <p>
            Also wird beim nächsten Urlaub wieder das komplette Gepäck mit der
            Post vorgeschickt, das Hundefutter direkt hinbeordert - bei 6 Golden
            kann das eine Menge sein - die Rücksitze vom Galaxy wieder ausgebaut
            und so können alle meine Mädels wieder mit mir reisen. Das wird
            herrlich!!
          </p>
          <p>viel Zeit ist inzwischen vergangen und viel ist passiert.</p>
          <p>
            mein bisheriges Leben brach außeinander und schwierige Zeiten
            standen ins Haus.
          </p>
          <p>
            Viele Menschen standen mir in dier Zeit bei und die Liebe zu meinen
            Golden half mir.
          </p>
          <p>
            Und so nahm ich all meine Kraft zusammen und entschloss, wieder ein
            kleines Golden Mädel in mein Rudel aufzunehmen. Und so kam 2015
            Maevis ( Sanguinisch Stop and Stare ) von Manita und Gert Busser -
            und wieder ist der Name Programm - nach Deutschland ins Haus und in
            mein Herz. Dieses kleine holländische Mädel mischte erst einmal
            alles auf. Gott sei Dank. Was habe ich gelacht über dieses
            Partygirl, das jeden Morgen die rote Papnase aufzug und Unsinn
            machte. Keine Schublade war sicher, kein Tupperdeckel hatte mehr
            eine Lasche, keine Blumenzwiebel überlebte. Was Maevis macht, macht
            sie mit 100%!. Sie ist eine wundervolle,sanfte und verschmuste
            Hündin geworden, die bei der Arbeit aber volle Energie zu tage
            bringt. Mein 20.000 Volt Mädel. Ich bin Manita und Gert so dankbar
            für dieses Powerpaket. Sie hat mich wieder ins Leben gebracht. DANKE
          </p>
          <p>
            Im neuen Zuhause viel dann der erste Wurf von Maevis und davon
            wollte ich eigentlich nichts behalten.... Aber da war dieser kleine
            Purzel mit seinen Augen, wie Mama, seinen Pfoten, einem Tiger gleich
            und einem Herz aus Gold. Wie viele Nächte trug ich ihn im T-Shirt,
            gekuschelt an meine Haut , stundenlang herum. Er hatte solche
            Blähungen und nichts schien zu wirken. Da hatte er sich schon einen
            Platz in meinem Herzen ausgesucht - nur ich wusste davon noch
            nichts...
          </p>
          <p>
            2018 wurde meine geliebte Holly aus dem Leben gerissen. Ihr Name
            Eowy f. Goldn Eyes of Munroe -was soviel heißt wie " Kämpferin " war
            Programm. Eine temperamentvolles Golden Mädel mit so viel
            Charm.Holly war auf Ausstellungen sehr erfolgreich. Sie schwebte
            gerade zu im Ring.
          </p>
          <p>
            Der erste Urlaub nach langer Zeit war geplant und Holly wurde krank.
            Eine Zecke hatte sie gebissen - Anaplasmose. Die Tierklinik gab mir
            Hoffnung, das wird wieder. Aber leider war es wohl eine ganz
            besonders aggressive Art. Holly kämpfte tapfer - und verlor......
            Ihre Mutter Meggie und ich vielen iin ein großes Loch. Sie wurde nur
            9 Jahre alt. Das ihre Mutter ihr zwei Jaher später folgen sollte,
            dass wusste ich da noch nicht.
          </p>
          <p>
            2019 ist meine geliebte Phoebe über die Regenbogenbrücke gegangen.
            Sie war etwas ganz besonderes. Als kleines Purzele holten wir sie
            damals bei Sylvia u. Uwe Sponholz ab und so begann unsere gemeinsame
            Zeit. Sie schenkte mir ihre Tochter Rosalie und nun wird ihre
            Ur-Enkelin Anjuly diese wunderolle Linie weiter führen.
          </p>
          <p>
            Sie durfte 14 Jahre werden, war in ihrem Leben nie krank und ein
            Bild von einem Golden.
          </p>
          <p>
            Den Umzug ins neue Zuhause hat sie widerwillig hingenommen und noch
            wochenlang saß sie abends an der Tür - gepackt mit ihrer
            Lieblingsdecke und dem Spielie und wollte heim.Sie liebte es, die
            Welpen stramm stehen zu lassen und mit einem Blick konnte sie einen
            40 kg Rüden ins " Platz " bringen. Eine beeindruckende
            Persönlichkeit - Du fehlst mein Schatz.
          </p>
          <p>
            2020 das Jahr an dem abermals die Welt für mich still stand!. Mit
            fast 14 Jahren verstarb mein Fels in der Brandung, meine
            Seelenverwandte, meine über alles geliebte Meggie ( Briannah f.
            Golden Eyes of Munroe).
          </p>
          <p>
            2019 bekam sie den ersten Schlaganfall. Zuerst dachten wir, es wäre
            ein Vesibular-Syndrom, Schwindel der vom Ohr ausging. Mit
            Medikamenten, Physio, Unterwassergymnastik und viel Liebe ging es
            ihr bald wieder besser. Sie bekam ein tolles Hundegeschirr an und
            ein Wägelchen für " Oma " wurde kauft. Darin saß sie wie die Queen
            und grinste vor sich hin, während das Volk daneben lief. Auf einer
            Wiese angekommen durfte sie dann erst einmal gemütlich alles
            abschnuppern und sich im frischen Gras wälzen. Danach gab es den
            Oma-Drink - Malzbier - das liebte sie. Dann kam der letzte Urlaub an
            der Nordsee - wir wussten es beide. Sie genoss noch einmal ihren
            geliebten Wind im Fell, da hob sie immer den Kopf und schloss die
            Augen, den Duft von geschnittenem Gras und die Leckereien, unsere
            Schmuseeinheiten. Sie schlief viel.
          </p>

          <p>
            Dann kam der zweite Schlaganfall. Das Gesicht hing herunter und die
            Frage aller Fragen stand plötzlich im Raum..... Aber auch dieses Mal
            schaffte es mein Irisches Mädel wieder. Sie konnte nicht mehr
            bellen, aber wieder selbständig fressen und laufen, nur mit dem
            Aufstehen, das ging nicht mehr. Mit dem Geschirr klappte es aber
            noch richtig gut und einmal " aufgestellt " lief sie schwanzwedelnd
            davon.
          </p>

          <p>
            Im Juli 2020 ist sie dann hier im neuen Zuhause in meinen Armen
            eingeschlafen. Der Tierarzt kam ins Haus und so gab es keine
            Aufregung für sie. Es war herzzerreißend, wie das Rudel von dieser
            wunderbaren Lady Abschied nahm. Sie war ja schon immer da gewesen.
            Sie legten sich alle behutsam zu ihr. Keiner wollte an diesem Abend
            etwas fressen.... Es wurde eine lange Nacht. Meine Rosalie wollte
            ihre Meggie nicht verlassen, als es Zeit war sie auf die Reise zu
            schicken. Ich war ihr auch keine große Hilfe.
          </p>

          <p>
            Drei so wunderbare Seelen in 3 Jahren zu verlieren ist eine harte
            Probe. Rosalie, Maevis, Anjuly und der " Quotenbub " James haben mir
            sehr geholfen. Wieder einmal konnte ich von meinen Golden lernen und
            ich bin dankbar für alles.
          </p>
          <p>
            Dieses Jahr wird es wohl keinen Wurf geben, Urlaub ist geplant -
            wenn Corona es zulässt - an meine geliebte Nordsee. Kraft tanken,
            Fischbrötchen essen, Tote Tante trinken und lange Spaziergänge am
            Meer machen, gute Freunde besuchen und das Leben genießen.
          </p> */}
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query UeberMichPageQuery {
    allUebermichYaml {
      edges {
        node {
          img {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default UeberMich;
